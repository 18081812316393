import { Component, Injector, ViewEncapsulation, VERSION, Renderer2 } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeUserLanguageDto, ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { LanguageData } from '../language-picker-local/LanguageConsts';

@Component({
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'language-picker',
})

export class LanguagePickerComponent extends AppComponentBase {
    nativeIndex: number = 1;
    language: abp.localization.ILanguageInfo = abp.localization.currentLanguage;
    showPicker: boolean = false;
    languages: abp.localization.ILanguageInfo[] = new Array(3);
    flagSrc: string[] = LanguageData.flagSrcs;

    imageSrc: string = this.flagSrc[0];
    menuOpen: boolean = false;
    menuBtnClick: boolean = false;

    constructor(
        injector: Injector,
        private _profileServiceProxy: ProfileServiceProxy,
        private renderer: Renderer2

    ) {
        super(injector)
    }

    ngOnInit(): void {

        switch (abp.localization.currentLanguage.name) {
            case "hy":
                this.imageSrc = this.flagSrc[1];
                this.nativeIndex = 1;
                break;
            case "sq":
                this.imageSrc = this.flagSrc[2];
                this.nativeIndex = 2;
                break;
            case "ka":
                this.imageSrc = this.flagSrc[3];
                this.nativeIndex = 3;
                break;
            default:
                this.imageSrc = this.flagSrc[0];
                this.nativeIndex = 0;
                break;
        }
        this.getLanguages();
        this.languages.forEach(element => {
            element.displayName = element.displayName.substring(0, 8);
        });
    }

    getLanguages(): void {
        this.languages = [abp.localization.languages.find(x => x.name === "en-GB"), abp.localization.languages.find(x => x.name === "hy"), abp.localization.languages.find(x => x.name === "sq"),
        abp.localization.languages.find(x => x.name === "ka")];
    }

    onSelected(value: number): void {
        this.nativeIndex = value;
        this.ShowHideLanguagePicker();
        this.imageSrc = this.flagSrc[value];
        this.changeLanguage(this.language.name);
    }

    ShowHideLanguagePicker(): void {
        if (this.showPicker) {
            this.showPicker = false;
        } else {
            this.showPicker = true;
        }
        this.languages.forEach(element => {
            element.displayName = element.displayName.substring(0, 8);
        });
        this.language = this.languages[this.nativeIndex];
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    }

    toggleMenu() {
        this.showPicker = !this.showPicker;
    }

    preventCloseOnClick() {
        this.menuBtnClick = true;
    }

    forTogglingView():void{
        this.renderer.listen('window', 'click', (e: Event) => {
            if (!this.menuBtnClick) {
                this.showPicker = false;
            }
            this.menuBtnClick = false;
        });
    }
}
