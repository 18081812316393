<div class="language_picker-container" (click)="ShowHideLanguagePicker(); preventCloseOnClick()" >
  <img src={{imageSrc}} alt="flag" class="dropdown-flag">
  <img *ngIf="showPicker" class="language_picker-arrow_down" />
  <img *ngIf="!showPicker" class="language_picker-arrow_up" />
</div>

<div *ngIf="showPicker" class="language_picker-wrapper" (click)="preventCloseOnClick()">
  <div class="dropdown-container">
    <label class="dropdown-label">I speak..</label>
    <div class="dropdown-pick_container">
      <img src={{imageSrc}} alt="flag" class="dropdown-flag">
      <select #forLanguage (change)="onSelected($event.target.value)" class="dropdown-list">
        <option *ngFor="let nativeLanguage of languages, let i = index" [selected]="i==nativeIndex" [value]="i">
          <!-- {{languages[i].displayName}} -->
          <!-- {{names[i]}} -->
        </option>
      </select>
    </div>
  </div>
</div>