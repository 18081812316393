<main class="mainDownloadPage">
  <siteHeader title="Games" imgSrc="/assets/common/images/games/{{GamesHeader}}.png" backGroundColor="#5469DE"
    color="#F8E031"></siteHeader>
  <div class="downloadPage">


    <div class="www">
      <a  *ngFor="let _urlOfLanguage of _urlsOfLanguages$ | async" href="{{_urlOfLanguage.url}}" class="ourShowImg">
        <img src="/assets/common/images/downloadPage/OurShow.png" alt="">
        <h1>{{_urlOfLanguage?.language}}</h1>
      </a>
    </div>
    <div class="qqq">
    <div class="characters">
     
        <img id="char1" src="/assets/common/images/downloadPage/Character1.png" alt="">
    </div>
    <div class="characters">
    
      <img  id="char2" src="/assets/common/images/downloadPage/Character2.png" alt="">
  </div>
  <div class="characters">
    
    <img  id="char3" src="/assets/common/images/downloadPage/Character3.png" alt="">
</div>
<div class="characters">
  
  <img  id="char4" src="/assets/common/images/downloadPage/Character4.png" alt="">
</div>
    </div>
  </div>
</main>
<site-footer color="#FFCD2E"></site-footer>