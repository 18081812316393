import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppUrlForLanguageDto, EpisodeDto, StorageServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { Language, LanguageData } from '../language-picker-local/LanguageConsts';
import { AppConsts } from '@shared/AppConsts';
import { BehaviorSubject, merge, mergeMap, tap } from 'rxjs';
import { LanguageService } from '../language-picker-local/language-picker-local.service';

@Component({
    templateUrl: './downloadPage.component.html',
    styleUrls: ['./downloadPage.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'downloadPage',
})


export class DownloadPageComponent extends AppComponentBase{
    GamesHeader: string = "Games_Header";

    
    // episodes: EpisodeDto[] = [];
    // urlVocabulary: string = '';
    // nativeIndex: number = 0;
    // model: EpisodeDto = new EpisodeDto();
    // imageSource: string;
    // languages: Language[]=LanguageData.languages;
    // currentLanguageId: number = 0;
    // data: any;
    // label:string;
    urls: AppUrlForLanguageDto[];

    // @Input() borderColor: string="";
    // @Input() color: string="";
    // @Input() textColor: string="";
    // @Input() description: string="";

    constructor(
        injector: Injector,
        private _storageService: StorageServiceServiceProxy,
        
        
        ) {
        debugger;
        super(injector);
        this._streams.subscribe();
        

        this._storageService.getAppUrls();

    }


    
    ngOnInit(): void {
        debugger;
        // console.log(this._downloadUrlsService.urls);
        
        // this._downloadUrlsService.urls;
        // console.log(this.urls);
        // console.log( this._languageService.tetantLanguageName$.subscribe((x)=>console.log(x)));

        
    };

    readonly _urlsOfLanguages$=this._storageService.getAppUrls();
    private readonly _streams = merge(
        // this._feedBackRequiredForTenant$,
    )
}


