import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';


@Component({
    templateUrl: './playicon.component.html',
    styleUrls: ['./playicon.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'playicon',
})
export class PlayIconComponent extends AppComponentBase {

    @Input() color: string="#1EA282";
    @Input() arrowColor: string="#071d64";

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}