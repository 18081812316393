import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ActivatedRoute } from '@angular/router';
import { GameDto, GamesData } from '../../games/GamesData';


@Component({
    templateUrl: './unity-game.component.html',
    styleUrls: ['./unity-game.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'unity',
})
export class UnityComponent extends AppComponentBase {

    games:GameDto[]=GamesData.games;
    game:GameDto;
    isLoaded: boolean=true;
    id:number;
    
    constructor(
        injector: Injector,
        private route: ActivatedRoute    ) {
        super(injector);
        const id = Number(this.route.snapshot.paramMap.get('id'));
        this.id=id;
    }

    ngOnInit() : void {
        this.game=this.games[this.id]
   }

}