<div class="unity_game-panel">
  <div class="unity_game-mobile_window">Coming soon on mobiles!</div>
  <div class="unity_game-window">
    <div *ngIf="isLoaded" class="unity_game-loader">
      <div class="spinner"></div>
      <p class="unity_game-loading_text">
        Loading Game...
      </p>
    </div>
  </div>
  <div class="unity_game-bottom_panel">
    <span class="unity_game-title">{{game?.title}}</span>
    <div class="unity_game-panel_buttons">
      <div routerLink="/app/games" class="unity_game-panel_back">
        &lt; Back to games
      </div>
      <button class="unity_game-panel_fullscreen">
        <img class="unity_game-panel_fullscreen-img" src="/assets/images/icons/fullscreen.png">
      </button>
    </div>
  </div>
</div>