<div class="image_box">
  <div class="image_box-flex_container">
    <a class="image_box-image">
      <div class="image_box-img_hover_zoom">
        <img src={{imageSource}}>
      </div>
    </a>
    <!-- <h3 class="image_box-title">{{title | localize }}</h3> -->
    <h3 class="image_box-title">{{title}}</h3>
    <div class="image_box-icon"> 
      <a>
        <img class="image_box-extra_image" src={{iconSource}}>
      </a>
    </div>
  </div>
</div>