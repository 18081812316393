import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GameDto } from '../games/GamesData';


@Component({
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'game',
})
export class GameComponent extends AppComponentBase {

    @Input() games: GameDto[] = [];
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {

    }
}