import { Component, Injector, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './image-box.component.html',
    styleUrls: ['./image-box.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'image-box',
})
export class ImageBoxComponent extends AppComponentBase {
    @Input() title: string;
    @Input() iconSource: string;
    @Input() imageSource: string;
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}
